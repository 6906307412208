import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { FormattedMessage } from "react-intl";
import { StaticImage } from "gatsby-plugin-image";

import { FirebaseContext } from "../../components/Firebase";
import Seo from "../../components/seo";
import { ContentBg, ContentBox } from "../../components/ui";

const AdminPage = () => {
  const [profiles, setProfiles] = useState([]);
  const { firebase, user } = useContext(FirebaseContext);
  useEffect(() => {
    if (!firebase) {
      navigate("/");
    } else {
      firebase.getPublicProfiles().then(profiles => {
        setProfiles(profiles);
      });
    }
  }, []);

  const runAfterUpdatingResults = async e => {
    e.preventDefault();
    const res = await firebase.runAfterUpdatingResults();
  };

  return (
    <ContentBg src="/static/c41c656219d120e7127cf400171a9d84/4c91a/players.png">
      <ContentBox>
        <Seo title="World Cup Poule 2022" />
        <h1>World Cup Poule 2022 - Admin</h1>
        {user && user.userId === process.env.GATSBY_FIREBASE_ADMIN_UID ? (
          <>
            <ul>
              <li key="x">
                <Link to="../schedule">
                  <FormattedMessage id="wc2022_schedule" />
                </Link>
              </li>
              <li key="e">
                <a href="#" onClick={runAfterUpdatingResults}>
                  Run after updating results
                </a>
              </li>
            </ul>
            <hr />
            <h3>Participants</h3>
            <ol>
              {profiles.map(profile => (
                <li key={profile.email}>
                  {profile.name || profile.email} |{" "}
                  <Link to={`../schedule?${profile.userId}`}>Predictions</Link>
                </li>
              ))}
            </ol>
            xx
            <StaticImage src="../../photos/wc2022/players.png" alt="worldcup" />
          </>
        ) : (
          <p>Kindly asking you to leave, cheater. :p</p>
        )}
      </ContentBox>
    </ContentBg>
  );
};

export default AdminPage;
